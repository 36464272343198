import PropTypes from 'prop-types';
import React from 'react';
import OmbudsmanPage from '../../containers/OmbudsmanPage';

const Ouvidoria = ({ location, pageContext }) => {
  return <OmbudsmanPage location={location} pageContext={pageContext} />;
};

Ouvidoria.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default Ouvidoria;
